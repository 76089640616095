import { Grid, Typography } from "@mui/material";
import ModalAdvanced from "../../../components/ModalAdvanced/ModalAdvanced";
import { useModalData } from "../../../hooks/useModalData";
import ReactPlayer from "react-player";

export const SeccionQueEs = ({ lang }: any) => {

    const [dataModal_video, handleShowModal_video, handleCloseModal_video] = useModalData()

    return (
        <>
            {
                lang === 'ES' && <SeccionQueEsES handleShowModal_video={handleShowModal_video} />
            }
            {
                lang === 'CA' && <SeccionQueEsCA handleShowModal_video={handleShowModal_video} />
            }

        </>
    )
};

const SeccionQueEsES = ({ handleShowModal_video }: any) => {

    return (
        <Grid container>

            <Grid item xs={12} md={5} className=''>
                <img src={`${process.env.PUBLIC_URL}/images/libro.png`} alt="" className='mx-auto mb-4' />
            </Grid>

            <Grid item xs={12} md={7} className='PageIndex__Beneficios--text animate__animated animate__fadeInLeftSmall'>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    ¿Qué es el Libro del Edificio Digital?
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    El Libro del Edificio Digital es una plataforma informática que hace mucho más sencilla la gestión de los edificios, permite reunir y registrar toda la información necesaria sobre el edificio y facilitar la comunicación entre los profesionales y los propietarios obligados a mantener el edificio en buen estado de conservación. Esta es la herramienta necesaria para garantizar que los “administradores de fincas” y los “técnicos de cabecera” formalicen la “historia clínica” para que el edificio pueda “estar en forma”.
                </Typography>


            </Grid>

            <Grid item xs={12} className=''>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    El Libro del Edificio Digital constituye una herramienta de redacción para formalizar el libro del edificio y una plataforma digital por la gestión y registro de la vida del edificio en los aspectos técnicos y administrativos. Sirve también para informar sobre las pautas necesarias para hacer un uso correcto de cada edificio y facilitar la planificación de las actividades de mantenimiento que tienen que permitir alargar la vida útil. También para garantizar la durabilidad, preservando las prestaciones y las características técnicas durante el máximo tiempo posible o planificar la renovación cuando sea necesario.
                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Permite:
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>

                    <ul>
                        <li>Digitalizar la información del edificio y convertir la información en datos.</li>
                        <li>Tener la información en un entorno accesible y promover la circularidad y la sostenibilidad.</li>
                        <li>Facilitar un mecanismo de mantenimiento dinámico de gestión integral.</li>
                    </ul>

                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Ventajas de usar LED:
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    LED como herramienta que permite gestionar los edificios de forma digital, integral y en tiempo real, ayuda a promover la cultura del mantenimiento preventivo, con lo que se alarga el ciclo de vida de los edificios. En consecuencias reduce su impacto ambiental y los costes de reparación.
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    Además, LED permite disponer de la información actualizada y accesible para todos los usuarios para facilitar la interoperatividad en el sistema de gestión, al tener la información guardada en una nube siempre está disponible y protegida; y permite acceder a los contenidos desde cualquier dispositivo y navegador.
                </Typography>

                <div id="before-productos"></div>

            </Grid>

        </Grid>
    )
}


const SeccionQueEsCA = ({ handleShowModal_video }: any) => {

    return (
        <Grid container>

            <Grid item xs={12} md={5} className=''>
                <img src={`${process.env.PUBLIC_URL}/images/libro.png`} alt="" className='mx-auto mb-4' />
            </Grid>

            <Grid item xs={12} md={7} className='PageIndex__Beneficios--text animate__animated animate__fadeInLeftSmall'>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Què és el Llibre de l'Edifici Digital?
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    El Llibre de l’Edifici Digital és una plataforma informàtica que fa molt més senzilla la gestió dels edificis, permet aplegar i registrar tota la informació necessària sobre l’edifici i facilitar la comunicació entre els professionals i els propietaris obligats a mantenir l’edifici en bon estat de conservació. Aquesta és l’eina necessària per garantir que els “administradors de finques” i els “tècnics de capçalera” formalitzin la “història clínica” perquè l’edifici pugui “estar en forma”. 
                </Typography>

            </Grid>

            <Grid item xs={12} className=''>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>
                    El Llibre de l'Edifici Digital constitueix una eina de redacció per formalitzar el llibre de l’edifici i una plataforma digital per la gestió i registre de la vida de l'edifici en els aspectes tècnics i administratius. Serveix també per informar sobre les pautes necessàries per fer un ús correcte de cada edifici i facilitar la planificació de les activitats de manteniment que han de permetre allargar-ne la vida útil. També per garantir-ne la durabilitat, preservant les prestacions i les característiques tècniques durant el màxim temps possible o planificar-ne la renovació quan sigui necessari.
                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Permet:
                </Typography>

                <Typography variant='h6' className='mb-4 fw-normal fs-105'>

                    <ul>
                        <li>Digitalitzar la informació de l'edifici i convertir la informació en dades.</li>
                        <li>Tenir la informació en un entorn accessible i promoure la circularitat i la sostenibilitat.</li>
                        <li>Facilitar un mecanisme de manteniment dinàmic de gestió integral.</li>
                    </ul>

                </Typography>

                <Typography variant='h5' className='mb-4 fw-bold'>
                    Avantatges d'usar LED:
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    LED com a plataforma que permet gestionar els edificis de forma digital, integral i en temps real, ajuda a promoure la cultura del manteniment preventiu, amb la qual cosa s'allarga el cicle de vida dels edificis. En conseqüències en redueix l'impacte ambiental i els costos de reparació.
                </Typography>

                <Typography variant='h6' className='mb-2 fw-normal fs-105'>
                    A més, LED permet disposar de la informació actualitzada i accessible per a tots els usuaris per facilitar la interoperativitat al sistema de gestió, com ara la informació guardada en un núvol sempre estigui disponible i protegida; i permeti accedir als continguts des de qualsevol dispositiu i navegador.
                </Typography>

                <div id="before-productos"></div>

            </Grid>

        </Grid>
    )
}
