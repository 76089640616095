import { Typography } from "@mui/material";

export const SeccionHeader = ({ lang }: any) => {

    return (
        <>
            {
                lang === 'ES' && <SeccionHeaderES />
            }
            {
                lang === 'CA' && <SeccionHeaderCA />
            }
        </>
    )
};

const SeccionHeaderES = () => {

    return (
        <>
            <div className="d-flex flex-column mb-1 pb-2">
                <div className="d-flex align-items-end">

                    <Typography variant='h4' className='fw-bold fs-1' color='white'>
                        LED Libro del Edificio Digital
                    </Typography>
                </div>
                <Typography variant='h5' className='fs-4 fw-semibold' color='white'>
                    smart buildings for smart cities
                </Typography>
            </div>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                La plataforma digital que transforma la gestión integral de los edificios, facilitando el acceso en cualquier momento a toda la información actualizada del edificio, así como la interoperatividad entre profesionales, propietarios y usuarios.
            </Typography>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Evita gastos superfluos, reduce costes, revalora el patrimonio, alarga la vida útil de los edificios y garantiza el cumplimiento legal. ¡Invierte menos para vivir mejor!
            </Typography>

            {/* <Typography variant='h6' className='mb-3 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                
            </Typography> */}

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Una revolución digital a la edificación.
            </Typography>
        </>
    )
}

const SeccionHeaderCA = () => {

    return (
        <>
            <div className="d-flex flex-column mb-1 pb-2">

                <div className="d-flex align-items-end">

                    <Typography variant='h4' className='fw-bold fs-1' color='white'>
                        LED Llibre de l'Edifici Digital
                    </Typography>
                </div>
                <Typography variant='h5' className='fs-4 fw-semibold' color='white'>
                    smart buildings for smart cities
                </Typography>
            </div>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                La plataforma digital que transforma la gestió integral dels edificis, facilitant l’accés en qualsevol moment a tota la informació actualitzada de l’edifici, així com la interoperativitat entre professionals, propietaris i usuaris.
            </Typography>

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Evita despeses supèrflues, redueix costos, revalora el patrimoni, allarga la vida útil dels edificis i garanteix el compliment legal. Inverteix menys per viure millor!
            </Typography>

            {/* <Typography variant='h6' className='mb-3 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                
            </Typography> */}

            <Typography variant='h6' className='mb-2 fw-normal animate__animated animate__fadeInLeftSmall lh-1-4 fs-105' component="p" color='white'>
                Una revolució digital a l'edificació.
            </Typography>
        </>
    )
}