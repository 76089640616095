import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLang } from "../../../language";
import jwt_decode from 'jwt-decode'
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import './TagIsAdmin.scss'

export const TagIsAdmin = () => {

    const Lang = useLang()

    const { auth } = useSelector((state: RootState) => state.auth)
    
    const { language } = useSelector((state: RootState) => state.language)

    const handleGoToAdmin = () => {

        sessionStorage.setItem(process.env.REACT_APP_MAIN_VARIABLE_ADMIN || '', JSON.stringify(auth || '{}'))

        window.open(`${process.env.REACT_APP_URL_APP_ADMINISTRACION}?locale=${String(language || '').toLowerCase()}`)
    }

    return (
        <div className='content-tag-view-admin' onClick={handleGoToAdmin}>
            <span className=' color-white'> 
                <FontAwesomeIcon icon={faEye} className='me-1' /> {Lang('ADMINISTRADOR')}
            </span>
        </div>
    )
};
