import { useState, useEffect } from 'react'
import { Grid } from "@mui/material";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

import './Metricas.scss'
import { useForm } from '../../../../../../hooks/useForm';
import { cloneDeep, isEmpty } from 'lodash';
import { useLang } from '../../../../../../language';
import { ElementoConstructivoServices, ElementosConstructivosServices } from '../../controllers/elemconst.services';
import { ModalStatusServer } from '../../../../../../components/Modals/ModalStatusServer';
import { isStatusSuccess, prefilterNodoHijos } from '../../../../../../helpers/helpers';
import { InicioOnlyReadSistemas } from './InicioOnlyReadSistemas/InicioOnlyReadSistemas';
import { Route, Routes, useNavigate } from 'react-router';
import { Metrica } from './Metrica';

export const Metricas = ({ fetchMisElemsConstData, id, idedificio }: any) => {

  const Lang = useLang()

  const [formChecks, handleInputChecks, handleFormChecks, resetFormChecks] = useForm({
    metricas: [],
  })

  const [firstLoad, setfirstLoad] = useState(true);

  // Evitar cambios de pagina sin guardar

  const [isBlockingNav, setisBlockingNav] = useState(false);

  const [tree, settree] = useState<any>({});

  useEffect(() => {

    if (fetchMisElemsConstData?.data) {

      // Buscar por ID

      const treeRootTemp = cloneDeep(fetchMisElemsConstData)

      const treeRoot = treeRootTemp?.data?.find((item: any) => item.id === id)

      if (treeRoot) {

        handleRecorrerArbol(treeRoot, '')

        settree(treeRoot)
      }

    }
  }, [fetchMisElemsConstData]);

  const handleRecorrerArbol = (nodo: any, path: string) => {

    nodo.hijos = prefilterNodoHijos(nodo.hijos)
    
    nodo.name = nodo.texto
    nodo.allowChildren = nodo.admite_hijos
    nodo.children = nodo.hijos
    nodo.idOriginal = nodo.id
    nodo.id = nodo.id + '|' + path

    if (Array.isArray(nodo.children)) {
      nodo.children?.map((item: any, index: number) => handleRecorrerArbol(item, path + '_' + index))
    }
  }

  const renderTree = (nodes: any) => (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={<span dangerouslySetInnerHTML={{ __html: decodeURIComponent(nodes.name) }}></span>}>
      {Array.isArray(nodes.children) && nodes.children?.[0]?.allowChildren ? nodes.children.map((node: any) => renderTree(node)) : null}
    </TreeItem>
  );

  const [expanded, setExpanded] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);

  const [selectedHistoric, setSelectedHistoric] = useState<any>([]);

  const [isClickFromTree, setisClickFromTree] = useState(false);

  const handleToggle = (event: React.SyntheticEvent, nodeIds: any) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: React.SyntheticEvent, nodeIds: any) => {

    setisClickFromTree(true)
    
    setSelected(nodeIds);
  };

  const [elementSelected, setelementSelected] = useState<any>({});

  useEffect(() => {

    if (typeof selected === 'string') {

      const indexPath = selected?.split('|_')?.[1]?.split('_') || []

      let objTemp: any = tree

      for (let i = 0; i < indexPath.length; i++) {
        objTemp = objTemp.children?.[indexPath[i]]
      }

      // Es no seleccionable con metricas

      if ((!objTemp.es_seleccionable && !isEmpty(objTemp.metricas)) || (objTemp.children?.length > 0 && objTemp.children[0]?.allowChildren === false)) {

        // setelementSelected({})

        // setfirstLoad(false)

        // setrolesdefault([])

        setSelectedHistoric([...selectedHistoric, selected])

        navigate(`./${objTemp.idOriginal}`)

        // fetchMiElemConst({
        //   id: idedificio + '/edificio',
        //   params: {
        //     padre_id: objTemp.idOriginal,
        //     arbol: true,
        //   }
        // })

      } else {

        // setelementSelected({})
      }
    }

  }, [selected]);

  const [rolesdefault, setrolesdefault] = useState([]);

  const navigate = useNavigate()

  useEffect(() => {

    if (!isEmpty(elementSelected)) {

      const children = elementSelected.children || []

      setrolesdefault(children)

      let metricasTemp: any = []

      for (let i = 0; i < children?.length; i++) {

        if (children[i].valor === '1') {
          metricasTemp.push(String(children[i].idOriginal))
        }

      }

      // Reconstruyendo valores de metricas

      let formMetricaTemp: any = {}

      // Elementos normales
      if (!isEmpty(children)) {

        for (let i = 0; i < children.length; i++) {

          formMetricaTemp[children[i]['idOriginal']] = {}

          if (!isEmpty(children[i]['metricas'])) {

            for (let j = 0; j < children[i]['metricas'].length; j++) {

              formMetricaTemp[children[i]['idOriginal']][children[i]['metricas'][j]['id']] = children[i]['metricas'][j]['valor']
            }
          }

        }

        // Elementos tipo metricas
      } else {

        formMetricaTemp[elementSelected.idOriginal] = {}

        if (!isEmpty(elementSelected['metricas'])) {

          for (let i = 0; i < elementSelected['metricas'].length; i++) {

            formMetricaTemp[elementSelected['idOriginal']][elementSelected['metricas'][i]['id']] = elementSelected['metricas'][i]['valor']
          }
        }
      }

      setformMetricas(formMetricaTemp)

      handleFormChecks({
        metricas: metricasTemp
      })
    }

  }, [elementSelected]);

  const [fetchMiElemConst, fetchMiElemConstData] = ElementosConstructivosServices.GET_BY_EDIFICIO()

  useEffect(() => {

    if (isStatusSuccess(fetchMiElemConstData.status)) {

      const objElem = fetchMiElemConstData.data?.[0]

      setelementSelected({
        ...objElem,
        name: objElem.texto,
        allowChildren: objElem.admite_hijos,
        children: objElem.hijos?.map((item: any) => {
          return {
            ...item,
            name: item.texto,
            allowChildren: item.admite_hijos,
            children: item.hijos,
            idOriginal: item.id,
          }
        }),
        idOriginal: objElem.id,
      })

    }

  }, [fetchMiElemConstData]);


  const [fetchElemConstPATCH, fetchElemConstPATCHData] = ElementoConstructivoServices.PATCH_BY_EDIFICIO()

  const handleFetchElemConstPATCH = () => {

    const metricasTemp = cloneDeep(formChecks.metricas)

    const rolesdefaultTemp = cloneDeep(rolesdefault)

    fetchElemConstPATCH({
      id: idedificio + '/edificio',
      body: {
        items: rolesdefaultTemp?.map((item: any) => {
          return {
            elemento_id: item.idOriginal,
            checked: metricasTemp.find((item2: any) => String(item2) === String(item.idOriginal)) ? true : false,
          }
        })
      }
    })
  }

  const handleToggleOtrosElementos = () => {

    setisClickFromTree(false)

    navigate(`./otros`)
  }

  const [fetchElemConstMetricaPOST, fetchElemConstMetricaPOSTData] = ElementoConstructivoServices.POST_BY_METRICAS(idedificio)

  const handleElemConstMetricaPATCH = () => {

    let metricas: any = []

    for (const key in formMetricas) {

      let objMetrica: any = {}

      objMetrica.elemento_id = key
      objMetrica.metricas = []

      for (const key2 in formMetricas[key]) {

        objMetrica.metricas.push({
          metrica_id: key2,
          valor: formMetricas[key][key2],
        })
      }

      metricas.push(objMetrica)
    }

    fetchElemConstMetricaPOST({
      body: {
        metricas
      }
    })

  }

  const [formMetricas, setformMetricas] = useState<any>({});

  const handleFormChangeMetricas = (idElem: string, id: string, e: any) => {

    const formMetricasTemp = cloneDeep(formMetricas)

    if (!formMetricasTemp[idElem]) formMetricasTemp[idElem] = {}

    formMetricasTemp[idElem][id] = e.target.value

    setformMetricas(formMetricasTemp)
  }

  const handleSubmitCheckYMetricas = ({ soloMetrica }: any) => {

    if (!soloMetrica) {

      handleFetchElemConstPATCH()
    }

    handleElemConstMetricaPATCH()

    setisBlockingNav(false)
  }

  useEffect(() => {

    if (isStatusSuccess(fetchElemConstMetricaPOSTData.status) && isStatusSuccess(fetchElemConstPATCHData.status)) {

      fetchMiElemConst({
        id: idedificio + '/edificio',
        params: {
          padre_id: elementSelected.idOriginal,
          arbol: true,
          // mostrar_todos: false,
        }
      })

      setelementSelected({})

    }

  }, [fetchElemConstMetricaPOSTData, fetchElemConstPATCHData]);

  useEffect(() => {

    if (isStatusSuccess(fetchElemConstMetricaPOSTData.status) && !isEmpty(elementSelected.metricas)) {

      fetchMiElemConst({
        id: idedificio + '/edificio',
        params: {
          padre_id: elementSelected.idOriginal,
          arbol: true,
          // mostrar_todos: false,
        }
      })

      setelementSelected({})

    }

  }, [fetchElemConstMetricaPOSTData]);

  return (
    <>
      <Grid container spacing={2} columnSpacing={3} className="">
        <Grid item xs={12} md={4} lg={3} xl={3}>
          <TreeView
            aria-label="rich object"
            defaultCollapseIcon={<FontAwesomeIcon icon={faChevronUp} />}
            defaultExpanded={['root']}
            defaultExpandIcon={<FontAwesomeIcon icon={faChevronDown} />}
            expanded={expanded}
            selected={selected}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            className="MetricasTreeView"
          >
            {
              tree.children?.map((item: any) => {
                return renderTree(item)
              })
            }

            {
              !isEmpty(tree.otros_elementos) && <TreeItem nodeId={'root'} label={Lang('OTROS_ELEMENTOS')} onClick={handleToggleOtrosElementos} ></TreeItem>
            }

          </TreeView>

        </Grid>

        <Grid item xs={12} md={8} lg={9} xl={9}>

          <Routes>

            <Route
              path="/"
              element={
                <InicioOnlyReadSistemas
                  idedificio={idedificio}
                  id={id}
                  fetchMiElemConstData={fetchMiElemConstData}
                  setfirstLoad={setfirstLoad}
                />
              }

            />

            <Route
              path="/:id"
              element={
                <Metrica
                  id={id}
                  firstLoad={firstLoad}
                  elementSelected={elementSelected}
                  setisBlockingNav={setisBlockingNav}
                  isBlockingNav={isBlockingNav}
                  fetchMiElemConst={fetchMiElemConst}
                  idedificio={idedificio}
                  fetchMiElemConstData={fetchMiElemConstData}
                  handleFormChangeMetricas={handleFormChangeMetricas}
                  formMetricas={formMetricas}
                  fetchElemConstMetricaPOSTData={fetchElemConstMetricaPOSTData}
                  formChecks={formChecks}
                  rolesdefault={rolesdefault}
                  handleInputChecks={handleInputChecks}
                  handleSubmitCheckYMetricas={handleSubmitCheckYMetricas}
                  fetchElemConstPATCHData={fetchElemConstPATCHData}
                  setelementSelected={setelementSelected}
                  setrolesdefault={setrolesdefault}
                  handleSelect={handleSelect}
                  setSelectedHistoric={setSelectedHistoric}
                  selectedHistoric={selectedHistoric}
                  isClickFromTree={isClickFromTree}
                  setisClickFromTree={setisClickFromTree}
                />
              }
            />

            {/* <Route
              path="*"
              element={<Navigate to={`/edificios/${id}/detalle/descripcion-constructiva`} replace />}
            /> */}
          </Routes>


        </Grid>
      </Grid>

      <ModalStatusServer fetchData={fetchElemConstMetricaPOSTData} onlyError />

      <ModalStatusServer fetchData={fetchElemConstPATCHData} onlyError />
    </>
  );
}
