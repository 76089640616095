const GENERALS = {
    CATALAN: 'Català',
    ESPANIOL: 'Espanyol',
    CASTELLANO: 'Castellà',
    INICIAR_SESION: 'Iniciar sessió',
    USUARIO: 'Usuari',
    CONTRASENIA: 'Contrasenya',
    LIBRO_DEL_EDIFICIO: `Llibre de l'edifici`,
    COLEGIADOS: 'Col·legiats',
    OTROS_USUARIOS: 'Altres usuaris',
    INICIA_SESION: 'Inicia sessió',
    REGISTRATE: `Registra't`,
    SALIR: 'Sortir',
    REGISTRARME: 'Registrar-me',
    INICIO: 'Inici',
    LIBROS: 'Llibres',
    USUARIOS: 'Usuaris',
    PLANTILLAS: 'Plantilles',
    PARAMETROS: 'Paràmetres',
    MIS_EDIFICIOS: 'Els meus edificis',
    CONFIGURACION: 'Configuració',
    NUEVO_EDIFICIO: 'Nou edifici',
    NOMBRE: 'Nom',
    APELLIDOS: 'Cognoms',
    NIF: 'NIF',
    TIPO_DE_USUARIO: `Tipus d'usuari`,
    INDICA_CUAL: 'Indica quin',
    COMUNIDAD_AUTONOMA: 'Comunitat autònoma',
    CORREO_ELECTRONICO: 'Correu electrònic',
    CAMBIO_DE_CONTRASENIA: 'Canvi de contrasenya',
    CONFIRMACION_DE_CONTRASENIA: 'Confirmació de contrasenya',
    CONTRASENIA_ACTUAL: 'Contrasenya actual',
    CANCELAR_LA_CUENTA: 'Cancel·lar el compte',
    GUARDAR: 'Desar',
    MIS_DATOS: 'Les meves dades',
    SOPORTE: 'Suport',
    AGENTES: 'Agents',
    DATOS_ADMINISTRATIVOS_JURIDICOS: 'Dades administratives i jurídiques',
    DESCRIPCION_CONSTRUCTIVA: 'Descripció constructiva',
    POTENCIAL_MEJORA_PLAN_ACTUACIONES: `Potencial de millora i Pla d'actuacions`,
    OPERACIONES: 'Operacions',
    DOCUMENTOS: 'Documents',
    IDENTIFICACION_EDIFICIO: `Identificació de l'edifici`,
    FOTOGRAFIAS_EMPLAZAMIENTO: 'Fotografies i emplaçament',
    PROPIEDAD: 'Propietat',
    EMPRESA_O_PROFESIONAL: 'Empresa o professional',
    IDENTIFICACION: 'Identificació',
    PROGRAMA_DE_REHABILITACION: 'Programa de rehabilitació',
    LIBRO_DE_MANTENIMIENTO: 'Llibre de manteniment',
    CREAR: 'Crear',
    NOMBRE_EDIFICIO: `Nom de l'edifici`,
    TIPO_EDIFICIO: `Tipus d'edifici`,
    REFERENCIA_CATASTRAL: 'Referència cadastral',
    NOTIFICACIONES: 'Notificacions',
    HISTORICO: 'Històric',
    NUEVO_USUARIO: 'Nou usuari',
    TIPO_DE_IDENTIFICACION: `Tipus d'identificació`,
    TIPO_DE_VIA: 'Tipus de via',
    DIRECCION: 'Adreça',
    CODIGO_POSTAL: 'Codi postal',
    POBLACION: 'Població',
    PROVINCIA: 'Provincia',
    TELEFONO: 'Telèfon',
    RAZON_SOCIAL: 'Raó social',
    NUMERO: 'Número',
    LIBRO_DEL_EDIFICIO_DIGITAL: `Llibre de l'Edifici Digital `,
    PRODUCTOS: 'Productes',
    ACCESO_GENERAL: 'Accés general',
    ACCESO_EDIFICIO: 'Accés edifici',
    OLVIDASTE_TU_CONTRASENIA: 'Has oblidat la contrasenya?',
    OLVIDASTE_TU_CODIGO_DE_ACCESO: `Has oblidat el codi d'accés?`,
    NUM_EDIFICIO: 'Núm. edifici',
    CODIGO_ACCESO: 'Codi accés',
    AREA_DE_ACCESO: `Àrea d'accés`,
    RECUPERAR_CONTRASENIA: 'Recuperar contrasenya',
    REESTABLECER_CONTRASENIA: 'Restablir contrasenya',
    RESTABLECER_CODIGO: 'Restablir codi',
    EDIFICIOS: 'Edificis',
    ADMINISTRADORES: 'Administradors',
    OPERACION_EXITOSA: 'Operació reeixida',
    OPERACION_FALLIDA: 'Operació fallida',
    EMAIL: 'Email',
    BUSCAR: 'Cercar',
    LIMPIAR: 'Esborrar',
    BUSCADOR: 'Cercador',
    LISTA_PROPIEDAD_AGENTES: 'Llista de Propietat i Agents',
    ACCESO_ADMINISTRACION: 'Accés administració',
    NUEVO_AGENTE: 'Nou Agent',
    EDITAR_AGENTE: 'Editar Agent',
    TIPO_AGENTE: 'Tipus Agent',
    TITULACION: 'Titulació',
    WEB: 'Web',
    TRABAJO: 'Treball',
    OBRA: 'Obra',
    AMBITO_DE_COLABORACION: 'Àmbit de col·laboració',
    PROYECTO_PARCIAL: 'Projecte parcial',
    SERVICIO: 'Servei',
    FOTOGRAFIAS_FACHADA: 'Fotografies de façana',
    PLANO_EMPLAZAMIENTO: `Plànol d'emplaçament`,
    MANTENER_ORIGINAL: 'Mantenir original',
    RECORTAR_IMAGEN: 'Retallar imatge',
    NUEVA_IMAGEN: 'Nova imatge',
    INFORME_INSPECCION_TECNICA_EDIFICIO: `Informe de la Inspecció / Avaluació Tècnica de l'Edifici`,
    CERTIFICADO_EFICIENCIA_ENERGETICA_ESTADO_INICIAL: 'Certificat eficiència energètica edifici',
    LICENCIAS: 'Llicències',
    DECLARACION_OBRA_NUEVA: `Declaració d'obra nova`,
    REGIMEN_PROPIEDAD: 'Règim de propietat',
    REGIMEN_ESPECIAL: 'Règim especial',
    CARGAS_REALES: 'Càrregues reals',
    ENTIDADES_JURIDICAS: 'Entitats jurídiques',
    GARANTIAS_PROMOTOR: 'Garanties del promotor',
    GARANTIAS_INSTALACIONES: 'Garanties de les instal·lacions',
    GARANTIAS_CONSTRUCTOR: 'Garanties del constructor',
    NUEVO_INFORME: 'Nou informe',
    REFERENCIA: 'Referència',
    FECHA: 'Data',
    DESCRIPCION: 'Descripció',
    CREAR_INFORME: 'Crear informe',
    EDITAR_INFORME: 'Editar informe',
    ELIMINAR: 'Eliminar',
    NUEVO_CERTIFICADO: 'Nou certificat',
    CREAR_CERTIFICADO: 'Crear certificat',
    EDITAR_CERTIFICADO: 'Editar certificat',
    NUMERO_REGISTRO: 'Número de registre',
    FECHA_FINALIZACION_VIGILANCIA: 'Data de finalització de la vigència',
    CALIFICACION_ENERGETICA: 'Qualificació energètica',
    CONSUMO_ENERGIA_PRIMARIA: `Consum d'energia primària (kWh/any)`,
    EMISIONES_CO2: 'Emissions de CO2 (kg/m2any)',
    CLASE: 'Classe',
    NUEVA_LICENCIA: 'Nova llicència',
    CREAR_LICENCIA: 'Crear llicència',
    EDITAR_LICENCIA: 'Editar llicència',
    NUEVA_DECLARACION: 'Nova declaració',
    CREAR_DECLARACION: 'Crear declaració',
    EDITAR_DECLARACION: 'Editar declaració',
    NOTARIO: 'Notari',
    NUMERO_PROTOCOLO: 'Número de protocol',
    NUEVO_REGIMEN: 'Nou règim',
    CREAR_REGIMEN: 'Crear règim',
    EDITAR_REGIMEN: 'Editar règim',
    TIPO: 'Tipus',
    ESCRITURA: 'Escriptura',
    REFERENCIA_DOCUMENTO_ACREDITATIVO: 'Referència del document acreditatiu',
    NUEVA_CARGA: 'Nova càrrega',
    CREAR_CARGA: 'Crear càrrega',
    EDITAR_CARGA: 'Edita càrrega',
    NUEVA_ENTIDAD: 'Nova entitat',
    CREAR_ENTIDAD: 'Crear entitat',
    EDITAR_ENTIDAD: 'Editar entitat',
    SUPERFICIE_M2: 'Superfície (m2)',
    DESTINACION: 'Destinació',
    CUOTA_PORCENTAJE: 'Quota (%)',
    FECHA_VENCIMIENTO_GARANTIA: 'Data de venciment de la garantia',
    TIPO_GARANTIA: 'Tipus de garantia',
    REFERENCIA_POLIZA_OTRO_DOCUMENTO: `Referència de la pòlissa o altre document acreditatiu de l'atorgament de la garantia`,
    NUEVA_GARANTIA: 'Nova garantia',
    CREAR_GARANTIA: 'Crear garantia',
    EDITAR_GARANTIA: 'Edita garantia',
    NOMBRE_EMPRESA_GARANTE: `Nom de l'empresa garant`,
    INSTALACION: 'Instal·lació',
    NOMBRE_VIA: 'Nom de la via',
    BLOQUE: 'Bloc',
    ANIO_FIN_CONSTRUCCION: 'Any fi construcció',
    FUENTE_ANIO_CONSTRUCCION: 'Font any construcció',
    OBSERVACIONES: 'Observacions',
    REGIMEN_JURIDICO: 'Règim jurídic de la propietat',
    DATOS_PROPIEDAD_PRESIDENTE_COMUNIDAD: 'Dades de la propietat o comunitat de propietaris',
    DATOS_PROPIEDAD: 'Dades de la propietat',
    DATOS_REPRESENTANTE: 'Dades representant',
    MEJORAR_PLAN: 'Millorar pla',
    CREAR_NUEVO_EDIFICIO: 'Crear nou edifici',
    TIPO_PRODUCTO: 'Tipus producte',
    DATOS_EDIFICIO: `Dades de l'edifici`,
    FACTURACION: 'Facturació',
    PAGO: 'Pagament',
    LISTA_DOCUMENTOS: 'Llista de documents',
    NUEVO_DOCUMENTO: 'Nou document',
    EDITAR_DOCUMENTO: 'Editar document',
    SELECCIONAR_ARCHIVO: 'Seleccionar fitxer',
    SIN_ARCHIVOS_SELECCIONADOS: 'Sense fitxers seleccionats',
    TIPO_DOCUMENTO: 'Tipus document',
    ELIMINAR_ARCHIVO: 'Eliminar fitxer',
    DESCARGAR: 'Descarregar',
    DOCUMENTO: 'Document',
    PAGAR: 'Pagar',
    PAGAR_PRODUCTO: 'Pagar producte',
    PENDIENTE_PAGO: 'Pendent de pagament',
    CANCELAR: 'Cancel·la',
    CONFIRMACION: 'Confirmació',
    LISTA_USUARIOS: `Llista d'usuaris`,
    NUESTROS_PRODUCTOS: 'Els nostres productes',
    AVISO_LEGAL: 'Avís legal',
    AVISO_LEGAL_CONDICIONES_USO: `Avís legal i condicions d'ús`,
    CONTACTANOS: `Contacta'ns`,
    ENVIAR: 'Enviar',
    ASUNTO: 'Assumpte',
    MENSAJE: 'Missatge',
    CONTACTO: 'Contacte',
    TARIFAS: 'Tarifes',
    AGREGAR_USUARIO: 'Afegir usuari',
    BUSCAR_NIF: 'Buscar NIF',
    DATOS_GENERALES: 'Dades generals',
    ASIGNAR_ROLES: 'Assignar perfils',
    CREAR_USUARIO: 'Crear Usuari',
    ROL: 'Perfil',
    ROLES: 'Perfils',
    MODIFICAR_ROLES: 'Modificar perfils',
    LISTA_INMUEBLES: `Llistat d'immobles`,
    AGREGAR_INMUEBLE: 'Afegeix immoble',
    LOCALIZACION: 'Localització',
    REF_CATASTRAL: 'Ref. Cadastral',
    COEFICIENTE: 'Coeficient',
    SUPERFICIE: 'Superfície',
    ANIO_CONSTRUCCION: 'Any de construcció',
    ELIMINAR_USUARIO: 'Eliminar usuari',
    SI_ELIMINAR: 'Sí, eliminar',
    MANTENIMIENTO_Y_MEJORAS: 'Manteniment i millores',
    FECHA_CREACION: 'Data de creació',
    ACEPTAR_Y_CONTINUAR: 'Acceptar i continuar',
    SI_RECHAZAR: 'Sí, rebutjar',
    RECHAZAR: 'Rebutjar',
    VERIFICADO: 'Verificat',
    CREAR_EDIFICIO: 'Crear Edifici',
    ADMINISTRADOR: 'Administrador',
    ROLES_ASIGNADOS: 'Perfils assignats',
    REGISTRAR_DATOS_FACTURACION: 'Registrar dades de facturació',
    COMARCA: 'Comarca',
    NOMBRE_CALLE: 'Nom del carrer',
    EDIFICIO: 'Edifici',
    ESCALERA: 'Escala',
    PISO: 'Pis',
    PUERTA: 'Porta',
    CIUDAD: 'Ciutat',
    MIS_DATOS_PERSONALES: 'Les meves dades personals',
    A_UNA_PERSONA: 'A una persona',
    A_UNA_EMPRESA: 'A una empresa',
    PAGADOR: 'Pagador',
    CIMENTACION: 'Fonamentació',
    ESTRUCTURA: 'Estructura',
    CERRAMIENTOS_VERTICALES: 'Tancaments verticals',
    CUBIERTA: 'Coberta',
    PARTICIONES_ACABADOS: 'Particions i acabats',
    SANEAMIENTO: 'Sanejament',
    SUMINISTRO_AGUA: `Subministrament d'aigua`,
    INSTALACION_ELECTRICA: 'Instal·lació elèctrica',
    INSTALACION_GAS: 'Instal·lació de gas',
    ACS_CALEFACCION_REFRIGERACION: 'ACS, calefacció i refrigeració',
    VENTILACION: 'Ventilació',
    PROTECCION_CONTRA_INCENDIOS: 'Protecció contra incendis',
    ASCENSORES: 'Ascensors',
    INSTALACION_TELECOMUNICACIONES: 'Instal·lació de telecomunicacions',
    INSTALACIONES_ESPECIALES: 'Instal·lacions especials',
    GESTIONAR_COMPONENTES: 'Gestionar components',
    COMPONENTES: 'Components',
    ELEMENTOS: 'Elements',
    SESION_CADUCADA: 'La sessió ha finalitzat',
    COMUNICACION: 'Comunicació',
    PLANIFICACION: 'Planificació',
    PROPIEDAD_AGENTES: 'Propietat i Agents',
    DOCUMENTACION: 'Documentació',
    ACTUALIZAR_DATOS: 'Actualitzar dades',
    CONVENIO: 'Conveni',
    POLITICA_PRIVACIDAD: 'Política de privadesa',
    POLITICA_COOKIES: 'Política de cookies',
    CONDICIONES_CONTRATACION: 'Condicions de contractació',
    PRECIO_REDUCIDO: 'Preu reduït',
    PRECIO_CONVENIO: 'Preu conveni',
    SELECCIONE_UN_PRODUCTO: 'Seleccioneu un producte',
    DESCRIPCION_POTENCIAL_MEJORA: 'Descripció del potencial de millores',
    REGISTRO_INCIDENCIAS_OPERACIONES: `Registre d'incidències i operacions`,
    REPOSITORIO_DOCUMENTOS: 'Biblioteca de documents',
    MANTENIMIENTO_PREVENTIVO: 'Manteniment preventiu',
    MANTENIMIENTO_CORRECTIVO: 'Manteniment correctiu',
    METRICAS: 'Mètriques',
    AGREGAR_ELEMENTO: 'Afegeix element',
    NUEVO_MANTENIMIENTO: 'Nou manteniment',
    EDITAR_MANTENIMIENTO: 'Editar manteniment',
    PERIODICIDAD: 'Periodicitat',
    PERIODICIDAD_EN_TEXTO: 'Periodicitat en text',
    DOCUMENTO_REFERENCIA: 'Document de referència',
    RESPONSABLE: 'Responsable',
    MANTENIMIENTO: 'Manteniment',
    MANTENIMIENTOS: 'Manteniments',
    MANUAL_DE_VIVIENDA: `Manual de l'habitatge`,
    OBLIGATORIO: 'Obligatori',
    MANTENIMIENTOS_PREVENTIVOS: 'Manteniments preventius',
    PROGRAMA_MANTENIMIENTO: 'Programa de manteniment',
    NUEVO_ELEMENTO: 'Nou element',
    EDITAR_ELEMENTO: 'Editar element',
    ANIO_INICIO: `Any d'inici`,
    ENVIAR_EMAIL: 'Enviar email',
    NECESARIO_SELECCIONAR_UNA_BUSQUEDA: `Cal seleccionar almenys 1 resultat de cerca`,
    CONTINUAR: 'Continuar',
    REMITENTE: 'Remitent',
    EMAIL_REMITENTE: 'Email del Remitent',
    PLANTILLA: 'Plantilla',
    LISTA_DESTINATARIOS: 'Llista de destinataris',
    DESTINATARIOS: 'Destinataris',
    SISTEMAS: 'Sistemes',
    OPERACIONES_MANTENIMIENTO: 'Operacions de manteniment',
    NUEVA_OPERACION: 'Nova operació',
    EDITAR_OPERACION: 'Editar operació',
    HERRAMIENTAS_SERVICIOS: 'Eines i serveis',
    OTROS_ELEMENTOS: 'Altres elements',
    DESMARCAR_ELEMENTOS_ELIMINACION_ASOCIADOS: `En desmarcar algun element, s'eliminaran totes les dades associades`,
    COMPLETAR: 'Completar',
    COMPLETADO: 'Completat',
    RECORDAR_NIF: 'Recordar NIF',
    ADJUNTAR_FICHEROS: 'Adjuntar fitxers',
    PARAMETROS_NORMATIVOS: 'Paràmetres normatius',
    VALORACION_EVALUACION: 'Valoració i Avaluació',
    NUEVO_PARAMETRO_NORMATIVO: 'Nou paràmetre normatiu',
    EDITAR_PARAMETRO_NORMATIVO: 'Editar paràmetre normatiu',
    FOTOGRAFIA_PARAMETRO_NO_DETECTADO: 'Fotografia del paràmetre normatiu de referència no detectat',
    PARAMETRO_NO_DETECTADO: 'Paràmetre normatiu de referència no detectat',
    NUEVA_PROPUESTA_MEJORA: 'Nova proposta millora',
    EDITAR_PROPUESTA_MEJORA: 'Editar proposta millora',
    PROPUESTAS_DE_MEJORA: 'Propostes de millora',
    PROPUESTA_DE_MEJORA: 'Proposta de millora',
    VENTAJAS: 'Avantatges',
    COSTE_ECONOMICO: 'Cost econòmic',
    DIFICULTAD_EJECUCION: `Dificultat d'execució`,
    INTERVENCIONES: 'Intervencions',
    PROPUESTA_MEDIDAS_MEJORA: 'PROPOSTES de mesures de millora',
    PROPUESTA_MEJORA: 'Proposta de millora',
    VENTAJAS_OBTENIDAS_MEJORA: 'AVANTATGES obtinguts amb la millora',
    COSTE_ECONOMICO_MEJORA: 'COST ECONÒMIC de la millora',
    DIFICULTAD_EJECUCION_MEJORA: 'DIFICULTAT D’EXECUCIÓ de la millora',
    VENTAJA_DE_PROPUESTA: 'Avantatge de la millora',
    COSTE_ECONOMICO_DE_MEJORA: 'Cost econòmic de la millora',
    JUSTIFICAR_DIFICULTAD_EJECUCION: 'Justificar la dificultat d’execució (si s’escau)',
    VOLVER_A_PROPUESTAS: 'Tornar a propostes',
    EDITAR_CONDICIONES: 'Editar condicions',
    NUEVA_INTERVENCION: 'Nova intervenció',
    EDITAR_INTERVENCION: 'Editar intervenció',
    DEFICIENCIAS: 'Deficiències',
    NUEVA_DEFICIENCIA: 'Nova deficiència',
    EDITAR_DEFICIENCIA: 'Editar deficiència',
    IMPORTAR_ITE: 'Importar ITE',
    IMPORTE_DE_OBRAS: 'Import de les obres',
    IMPORTE_DE_HONORARIOS: 'Import dels honoraris',
    IMPORTE_DE_TASAS: 'Import de les taxes',
    OTROS: 'Altres',
    MES_INICIO: `Mes d'inici`,
    ENERO: 'Gener',
    FEBRERO: 'Febrer',
    MARZO: 'Març',
    ABRIL: 'Abril',
    MAYO: 'Maig',
    JUNIO: 'Juny',
    JULIO: 'Juliol',
    AGOSTO: 'Agost',
    SEPTIEMBRE: 'Setembre',
    OCTUBRE: 'Octubre',
    NOVIEMBRE: 'Novembre',
    DICIEMBRE: 'Desembre',
    DURACION_MESES: 'Durada en mesos',
    DEFICIENCIAS_IMPORTADAS: 'Deficiències importades',
    DEFICIENCIAS_FICHERO_ITE: 'Deficiències fitxer ITE',
    AGREGAR_A_DEFICIENCIAS: 'Afegir a deficiències',
    SI_AGREGAR: 'Sí, afegir',
    LEVE: 'Lleu',
    IMPORTANTE: 'Important',
    GRAVE: 'Greu',
    MUY_GRAVE: 'Molt greu',
    CALIFICACION: 'Qualificació',
    IMAGENES: 'Imatges',
    IMAGEN: 'Imatge',
    MEDIDAS: 'Mesures',
    NUEVA_MEDIDA: 'Nova mesura',
    EDITAR_MEDIDA: 'Editar mesura',
    MEDIDA: 'Mesura',
    BENEFICIO: 'Benefici',
    COSTE_MINIMO: 'Cost mínim',
    COSTE_MAXIMO: 'Cost màxim',
    COMPLEJIDAD: 'Complexitat',
    COMENTARIOS: 'Comentaris',
    COSTE: 'Cost',
    RELACION_MEDIDAS: 'Relació de mesures',
    FASE: 'Fase',
    REQUISITO: 'Requisit',
    EXIGENCIAS: 'Exigències',
    DESCRIPCION_CORTA: 'Descripció curta',
    REEMPLAZAR_ITE: 'Reemplaçar ITE',
    REGRESAR: 'Tornar',
    AGENTES_EDIFICACION: `Agents de l'edificació`,
    VIVIENDAS: 'Habitatges',
    USO: 'Ús',
    VIVIENDA: 'Habitatge',
    MANUAL_USO_MANTENIMIENTO: `Manual d'ús i manteniment`,
    PLANOS_DE_VIVIENDA: `Plànols de l'habitatge`,
    INCLUIR_EN_LIBRO: 'Incloure en llibre',
    TIPO_PLANTILLA: 'Tipus de plantilla',
    EXCLUIR_DEFICIENCIAS_IMPORTADAS: 'Excloure deficiències importades',
    FECHA_INICIO: 'Data inici',
    ESTADO: 'Estat',
    ADJUNTO: 'Adjunt',
    PROPIETARIOS: 'Propietaris',
    LISTA_PROPIETARIOS: 'Llista de propietaris',
    VALIDO_HASTA: 'Vàlid fins',
    ACTUACION: 'Actuació',
    IMPORTAR: 'Importar',
    REDUCCION: 'Reducció',
    SELECCIONE_DEFICIENCIA_ITE_ACTUACION: `Seleccioneu una deficiència del fitxer ITE i associeu l'actuació`,
    FECHA_ENVIADO: 'Data enviat',
    FECHA_FIN: 'Data final',
    URGENTE: 'Urgent',
    POTENCIAL_MEJORAS: 'Potencial millores',
    ASIGNAR_FASES: 'Assignar fases',
    ELABORAR_PLAN: 'Elaborar pla',
    RESUMEN_DESCARGA: 'Resum i descàrrega',
    CATASTRO: 'Cadastre',
    ANIO_INICIO_CONSTRUCCION: 'Any inici construcció',
    LEIDOS: 'Llegits',
    LEIDO: 'Llegit',
    DESTINATARIO_NOMBRE: 'Destinatari nom',
    DESTINATARIO_EMAIL: 'Destinatari email',
    PROGRAMACION_GESTION: 'Programació i Gestió',
    RESUMEN_GENERAL: 'Resum general',
    VER_MAS: 'Veure més',
    PROYECTOS_FASES: 'Projectes i fases',
    PROYECTO: 'Projecte',
    PROYECTOS: 'Projectes',
    NUEVO_PROYECTO: 'Nou projecte',
    EDITAR_PROYECTO: 'Editar projecte',
    NUEVA_FASE: 'Nova fase',
    EDITAR_FASE: 'Editar fase',
    FASES: 'Fases',
    VOLVER_PROYECTOS: 'Tornar a projectes',
    ACTIVIDADES: 'Activitats',
    PROGRAMACION_COSTE: 'Programació i Cost',
    FINANCIACION: 'Finançament',
    NUEVA_ACTIVIDAD: 'Nova activitat',
    EDITAR_ACTIVIDAD: 'Editar activitat',
    TIPO_ACTIVIDAD: 'Tipus activitat',
    TITULO: 'Títol',
    SISTEMA: 'Sistema',
    REHABILITACION: 'Rehabilitació',
    MEJORA: 'Millora',
    INCIDENCIA: 'Incidència',
    FECHA_INICIO_DESDE: 'Data inici des de',
    FECHA_INICIO_HASTA: 'Data inici fins',
    MEJORAS: 'Millores',
    OPERACION: 'Operació',
    IMPORTAR_A_ACTIVIDAD: 'Importar a Activitat',
    EXCLUIR_IMPORTADOS: 'Excloure importats',
    ANOTACIONES: 'Anotacions',
    ACTIVIDAD: 'Activitat',
    NUEVA_ANOTACION: 'Nova anotació',
    EDITAR_ANOTACION: 'Editar anotació',
    FECHA_SEGUIMIENTO: 'Data seguiment',
    CALENDARIO: 'Calendari',
    GREMIOS: 'Gremis',
    IMAGENES_Y_DOCUMENTOS: 'Imatges i Documents',
    FASES_DE_MEJORA: 'Fases de millora',
    SI_DESMARCAR: 'Sí, desmarcar',
    IR_A_ACTIVIDAD: 'Anar a activitat',
    DOCUMENTACION_TECNICA: 'Documentació tècnica',
    DOCUMENTACION_ADMINISTRATIVA: 'Documentació administrativa',
    DOCUMENTACION_JURIDICA: 'Documentació jurídica',
    GENERADOR_DOCUMENTOS: 'Generador de documents',
    GENERAR_DOCUMENTO: 'Generar document',
    PLANIFICACION_FINANCIERA: 'Planificació financera',
    FONDOS_PROPIOS: 'Fons propis',
    SUBVENCIONES_SOLICITADAS: 'Subvencions sol·licitades',
    SUBVENCIONES_OTORGADAS: 'Subvencions atorgades',
    IMPORTE_FINANCIAR: 'Import a finançar',
    FORMA_FINANCIACION: 'Forma de finançament',
    FECHA_APROBACION_FINANCIACION: `Data d'aprovació finançament`,
    INFORMACION_PREVIA: `Informació prèvia`,
    GASTOS: 'Despeses',
    NUEVO_GASTO: 'Nova despesa',
    EDITAR_GASTO: 'Editar despesa',
    CONCEPTO: 'Concepte',
    IMPORTE: 'Import',
    MES: 'Mes',
    ANIO: 'Any',
    CREADO_POR: 'Creat per',
    DEFICIENCIA: 'Deficiència',
    VISTA_PREVIA: 'Vista prèvia',
    RESUMEN_FINANCIERO: 'Resum financer',
    TOTAL_IMPORTE_DE_OBRAS: `Total import d'obres`,
    TOTAL_IMPORTE_DE_HONORARIOS: `Total import d'honoraris`,
    TOTAL_IMPORTE_DE_TASAS: 'Total import de taxes',
    TOTAL_IMPORTE_OTROS: 'Total import altres',
    TOTAL: 'Total',
    TOTAL_FASE: 'Total Fase',
    CALENDARIO_PLANIFICACION_FINANCIERA: 'Calendari planificació financera',
    RESUMEN_FASE: 'Resum fase',
    GREMIO: 'Gremi',
    NUEVO_GREMIO: 'Nou gremi',
    EDITAR_GREMIO: 'Editar gremi',
    TIPO_GREMIO: 'Tipus gremi',
    AGREGAR_GREMIO: 'Afegir gremi',
    LISTA_GREMIOS: 'Llista de gremis',
    CAMBIAR_A_FINALIZADO: 'Canviar a finalitzat',
    CAMBIAR_A_PENDIENTE: 'Canviar a pendent',
    INGRESOS: 'Ingressos',
    TESORERIA: 'Tresoreria',
    FECHAS_CONSTRUCCION: 'Dates de construcció',
    LICENCIA_MUNISIPAL_OBRAS: `Llicència municipal d'obres`,
    FINALIZACION_OBRAS: `Finalització d'obres`,
    NUMERO_EXPEDIENTE: `Número d'expedient`,
    VISADO: 'Visat',
    RECEPCION_DEFINITIVA_OBRAS: `Recepció definitiva d'obres`,
    LICENCIA_PRIMERA_OCUPACION: `Llicència de primera ocupació`,
    CALIFICACION_PROVISIONAL_VPO: `Qualificació provisional HPO`,
    CALIFICACION_DEFINITIVA_VPO: `Qualificació definitiva VPO`,
    ESCRITURAS: `Escriptures`,
    LICENCIA_ACTIVIDADES_PARQUING: `Llicència d'activitats del pàrquing`,
    INCIDENCIAS: `Incidències`,
    NUEVA_INCIDENCIA: 'Nova incidència',
    EDITAR_INCIDENCIA: 'Editar incidència',
    TOMO: 'Tom',
    FOLIO: 'Foli',
    LIBRO: 'Llibre',
    NUMERO_FINCA: 'Número finca',
    REGISTRO: 'Registre',
    INSCRIPCION_GENERAL_DECLARACION_OBRA_NUEVA: `Inscripció registral de la declaració d'obra nova`,
    NUEVA_INSCRIPCION: 'Nova inscripció',
    EDITAR_INSCRIPCION: 'Editar inscripció',
    PERIODO: 'Període',
    NUEVO_PERIODO: 'Nou període',
    EDITAR_PERIODO: 'Editar període',
    PERIODOS: 'Períodes',
    PERIODOS_Y_FASES: 'Períodes i fases',
    VOLVER_PERIODOS: 'Tornar a períodes',
    DERRAMAS: 'Vessament',
    NUEVA_DERRAMA: 'Nova vessa',
    EDITAR_DERRAMA: 'Editar derrama',
    CUOTA: 'Quota',
    FECHA_BAJA: 'Data de baixa',
    MESES: 'Mesos',
    DAR_DE_BAJA: 'Donar de baixa',
    FINALIZAR: 'Finalitzar',
    IMPORTE_TOTAL: 'Import total',
    FONDOS_ECONOMICOS: 'Fons econòmics',
    FONDOS_PROPIOS_ACTUALES: 'Fons propis actuals',
    SUBVENCIONES: 'Subvencions',
    FORMA_FINANCIAMIENTO: 'Forma de finançament',
    DESCARGAR_RESUMEN_FINANCIERO: 'Descarregar resum financer',
    CAMBIAR_A: 'Canvia a',
    IMPORTE_TOTAL_A_FINANCIAR: 'Import total a finançar',
    IMPORTE_TOTAL_FINANCIADO: 'Import total finançat',
    RESUMEN_TOTAL: 'Resum total',
    TOTAL_PROYECTO: 'Total projecte',
    TOTAL_INGRESOS: 'Total ingressos',
    PENDIENTE_FINANCIAR: 'Pendent de finançar',
    TOTAL_PORCENTAJE_REDUCCION_CONSUMO_EPNR: 'Total % reducció consum EPnr',
    TOTAL_COSTE_MINIMO: 'Total Cost mínim',
    TOTAL_COSTE_MAXIMO: 'Total Cost màxim',
    ASOCIAR_FASES_AL_PERIODO: 'Afegir fases al període',
    ORDEN: 'Ordre',
    ORDEN_DE_VISUALIZACION: 'Ordre de visualització',
    LED_GESTION: 'LED Gestió del manteniment',
    LED_REDACCION: `LED Llibre de l'edifici`,
    TEST_ENERGETICO: 'Test energètic',
    TEST_ALUMINOSO: 'Test Aluminós',
    TEST_RADON: 'Test Radó',
    PRUEBAS_DE_LABORATORIO: 'Proves de laboratori',
    APARATOS_DE_MEDIDA: 'Aparells de mesura',
    IDENTIFICACION_MARCA_PROFESIONAL: 'Identificació marca personal',
    PROMOTOR_AGENTES: 'Promotor i Agents',
    PENDIENTE: 'Pendent',
    EDITAR: 'Edita',
    VER: 'Veure',
    VER_DETALLE: 'Veure detall',
    SELECCIONAR: 'Seleccionar',
    VER_HISTORICO: 'Veure històric',
    INSTRUCCIONES_USO_MANTENIMIENTO: `Instruccions d'ús i manteniment`,
    FIRMANTES: 'Signants',
    IMPORTAR_FIRMANTES: 'Importar signants',
    NUEVO_FIRMANTE: 'Nou signant',
    NOMBRE_Y_APELLIDOS: 'Nom i Cognoms',
    EDITAR_FIRMANTE: 'Editar signant',
    FECHA_FIRMA: 'Data signatura',
    NOMBRE_Y_APELLIDOS_REPRESENTANTE: 'Nom i cognoms del representant',
    IMPORTANDO_FIRMANTES: 'Important signants',
    FIRMANTE_LIBRO: 'Signant llibre',
    FIRMANTE_PROMOTOR: 'Signant promotor',
    FICHAS_DE_CAMPO: 'Fitxes de camp',
    AGREGAR_SISTEMAS: 'Afegir sistemes',
    PORTAL_GENERADOR_INFORMES_ITE: 'Portal generador informes ITE',
    RESUMEN_DE_MEDIDAS: 'Resum de mesures',
    COMFIRMACION_CREACION_EDIFICIO: `Confirmació de creació de l'edifici`,
    SI_CREAR: 'Sí, crear',
    CONDICIONES: 'Condicions',
    EN_ESTE_ENLACE: 'en aquest enllaç',
    PRECIO_GENERAL: 'Preu general',
    HAZ: 'Fes',
    CLICK_AQUI: 'clic aquí',
    TIPO_CALLE_AVENIDA: 'Tipus de carrer (Carrer, Avinguda...)',
    IMPORTE_TOTAL_PAGAR: 'Import total a pagar',
    IVA_INCLUIDO: 'IVA inclòs',
    SELECCIONE_UNA_VIA_PAGO: 'Seleccioneu una via de pagament',
    BUSCAR_EDIFICIO_NOMBRE_REFERENCIA: 'Buscar edifici per Nom o Referència cadastral',
    PROFESION: 'Professió',
    OTRA_PROFESION: 'Indicar professió',
    NUMERO_DE_COLEGIADO: 'Número de col·legiat',
    COLEGIO_PROFESIONAL: 'Col·legi professional',
    PAIS: 'País',
    AGREGAR_UNA_IMAGEN: 'Afegeix una imatge',
    AGREGAR_A_ACTIVIDADES: 'Afegir a activitats',
    REGISTRARSE_Y_COMPRAR: 'Registrar-se i comprar',
    RESUMEN: 'Resum',
    REDUCCION_CONSUMO: 'reducció consum',
    USE_ARRASTRE_ICONO: 'Utilitzeu i arrossegueu la icona',
    PARA_CAMBIAR_ORDEN: `per canviar d'ordre`,
    RESUMEN_FASES: 'Resum de fases',
    ENLACES_EXTERNOS: 'Enllaços externs',
    REGRESAR_AL_EDIFICIO: `Tornar a l'edifici`,
    ESCRIBA_SU_NIF: 'Escriviu el vostre NIF',
    NUESTROS_MODULOS: 'Els nostres mòduls',
    SIN_IVA: 'Sense IVA',
    TARIFA_GENERAL: 'Tarifa general',
    TARIFA_CONVENIO: 'Tarifa conveni',
    POTENCIAL_DE_MEJORAS: 'Potencial de millores',
    ACEPTAR: 'Acceptar',
    DATOS_ADICIONALES: 'Dades addicionals',
    RESUMEN_COSTES: 'Resum costos',
    INCLUIR_MANUAL_VIVIENDA: `Incloure al Manual de l'habitatge`,
    PROMOTORES_AGENTES_EDIFICACION: `Promotors i agents de l'edificació`,
    NUEVA_ACTUACION: 'Nova actuació',    
    EDITAR_ACTUACION: 'Editar actuació',
    ACTUACIONES: 'Actuacions',
    ACEPTACION_PAGO_CUENTA: 'Acceptació de pagament a compte',
    BAJA_SUSCRIPCION: 'Baixa de subscripció',
    ELIMINAR_EDIFICIO: 'Eliminar edifici',
    IMPORTAR_SISTEMAS_OTRO_EDIFICIO: `Importar Sistemes des d'un altre Edifici`,
    SELECCIONA_UNA_OPCION: 'Seleccioneu una opció',
    LISTA_EDIFICIOS: `Llista d'edificis`,
    ANIO_S: 'Any(s)',
    MES_ES: 'Mes(os)',
    UNIDAD: 'Unitat',
    INCLUIR_OPERACION: 'Incloure operació de manteniment',
    SI: 'Si',
    NO: 'No',
    CREAR_GREMIO: 'Crear gremi',
    SINCRONIZAR_CATASTRO: 'Sincronitzar cadastre',
    ACTIVO: 'Actiu',
    EMPRESAS_COLABORADORAS: 'Empreses col·laboradores',
    MONITORIZACION: 'Monitorització',
    PROXIMAMENTE: 'Properament',
    DOMOTICA_INMOTICA: 'Domòtica / Immòtica',
    ACTUACIONES_DE_REHABILITACION: 'Actuacions de rehabilitació',
    ACEPTACION_INGRESO_EDIFICIO: `Acceptació a l'ingrés de l'edifici`,
    MEDIDAS_DE_MEJORA: 'Mesures de millora',
    GREMIOS_ASIGNADOS: 'Gremis assignats',
    RESUMEN_FINANCIERO_GASTOS_FASES_PLANIFICACION_FINANCIERA: 'Resum financer (Despeses per fases / Planificació financera)',
    FINANCIACION_BANCARIA: 'Finançament bancari',
    SABER_MAS: 'Saber-ne més',
    MAS_INFORMACION: 'Més informació',
    VER_TARIFAS: 'Veure tarifes',
    GENERAR_ACTIVIDADES_PERIODICAS: 'Generar activitats periòdiques',
    TOTAL_DERRAMAS_FINALIZADAS: 'Total vesses finalitzades',
    ENTIDAD_JURIDICA: 'Entitat jurídica',
    NUEVA_ENTIDAD_JURIDICA: 'Nova entitat jurídica',
    EDITAR_ENTIDAD_JURIDICA: 'Editar entitat jurídica',
    CONTACTAR: 'Contactar',
    COMO_USUARIO_COMO_INVITADO: 'Com a usuari registrat o com a convidat',
    OCULTAR: 'Amagar',
    CREAR_NUEVA_CUENTA: 'Crear un compte nou',
    PROPIETARIO_EDIFICIO_VIRTUAL: 'Propietari edifici virtual',
    FINALIZADO: 'Finalitzat',
    FECHA_FINALIZACION: 'Data finalització',
    FECHA_FINALIZACION_DESDE: 'Data finalització des de',
    FECHA_FINALIZACION_HASTA: 'Data finalització fins',
    AVISO_LEGAL_POLITICA_PRIVACIDAD: 'Avís legal i Política de privadesa',
    AVISO_LEGAL_CONDICIONES_USO_WEB: `Avís legal i condicions d'ús de la web`,
    FUNCIONAMIENTO_NUESTRO_SITIO_WEB: 'Funcionament del nostre lloc web',
    CONDICIONES_USO_SITIO_WEB_TODOS_USUARIOS: `Condicions d'ús del nostre lloc web per a tots els usuaris web`,
    REGIMEN_RESPONSABILIDADES: 'Règim de responsabilitats',
    POLITICA_CONTENIDOS: 'Política de continguts',
    DERECHOS_PROPIEDAD_INTELECTUAL: 'Drets de propietat intel·lectual',
    DURACION_FINALIZACION: 'Durada i finalització',
    PRECIOS_PRODUCTOS_SERVICIOS: 'Preus dels productes i serveis',
    JURISDICCION_COMPETENCIA: 'Jurisdicció i competència',
    PROTECCION_DATOS: 'Protecció de dades',
    CONDICIONES_GENERALES_PRODUCTOS_LED: 'Condicions generals de contractació productes LED',
    CONDICIONES_PARTICULARES_PRODUCTOS_LED: `Condicions particulars de contractació productes LED`,
    AMBITO_APLICACION_OBJETO: `Àmbit d'aplicació i objecte`,
    IDIOMA: 'Idioma',
    PROCESO_CONTRATACION: 'Procés de contractació',
    INFORMACION_PRODUCTOS_SERVICIOS_DIGITALES: 'Informació sobre els productes i serveis digitals',
    CONDICIONES_ECONOMICAS: 'Condicions econòmiques',
    GARANTIA_LEGAL: 'Garantía Legal',
    DESESTIMIENTO_DEVOLUCIONES_INCIDENCIAS: 'Desistiment, devolucions i incidències',
    RESPONSABILIDADES: 'Responsabilitats',
    VIGENCIA_CONTRATO: 'Vigència del contracte',
    PROPIEDAD_INTELECTUAL_INDUSTRIAL: 'Propietat intel·lectual i industrial',
    ATENCION_CLIENTE_RECLAMACIONES: 'Atenció al Client i Reclamacions',
    HOJAS_RECLAMACION: 'Fulls de reclamació',
    LEGISLACION_APLICABLE_JURISDICCION_COMPETENTE: 'Legislació Aplicable i Jurisdicció competent',
    OBJETO_CONTRATO: 'Objecte del contracte',
    CARACTERISTICAS_HERRAMIENTA_FUNCIONAMIENTO: `Característiques de l'eina i el funcionament`,
    PRECIO: 'Preu',
    PROCESO_COMPRA: 'Procés de compra',
    FORMA_PAGO: 'Forma de pagament',
    ATENCION_POSTVENTA: 'Atenció postvenda',
    DESISTIMIENTO: 'Desistiment',
    DOCUMENTOS_EDIFICIO_PENDIENTES: `Documents d'Edifici pendents`,
    MARCAR_COMO_LEIDO: 'Marcar com a llegit',
    DOCUMENTOS_INMUEBLE_PENDIENTES: `Documents d'Immoble pendents`,
    REAPERTURAR: 'Reobertura',
    PROGRAMA_MANTENIMIENTO_REHABILITACION_RENOVACION: 'Programa de rehabilitació',
    PLANTILLA_DOCUMENTO_SEPA: 'Plantilla Document SEPA',
    PROFESIONALES: 'Professionals',
    TECNICOS_ESPECIALISTAS_EN_GESTION_MANTENIMIENTO: 'Tècnics especialistes en gestió del manteniment',
    MANUAL_DE_LA_VIVIENDA: `Manual de l'habitatge`,
    REPRESENTANTE: 'Representant',
    ES_PROPIETARIO_REPRESENTANTE_LEGAL: `És el propietari/ària o representant legal de la propietat de l'edifici?`,
    DATOS_PROPIETARIO_REPRESENTANTE: 'Dades del propietari/ària o Representant legal',
    DATOS_PROPIEDAD_REPRESENTANTE_LEGAL: 'Dades de la propietat o representant legal de la propietat',
    PERFIL_REPRESENTANTE_LEGAL: `Perfil que exerceix a l'edifici`,
    ASIGNAR_ESPECIALISTA: 'Assignar especialista',
    NIF_ESPECIALISTA: `NIF/NIE de l'especialista`,
    PENDIENTE_DOCUMENTO_SEPA: 'Pendent document SEPA',
    COMUNICACIONES: 'Comunicacions',
    DOCS_EDIFICIO: 'Docs Edifici',
    DOCS_INMUEBLES: 'Docs Propietats',
    USUARIO_INVITADO: 'Usuari convidat',
    TEXTO: 'Text',
    NUMERICO: 'Numèric',
    COSTO: 'Cost',
    DIFICULTAD_EJECUCION_NULA: `Dificultat d’execució nul·la`,
    DIFICULTAD_EJECUCION_BAJA: `Dificultat d’execució baixa`,
    DIFICULTAD_EJECUCION_MEDIA: `Dificultat d’execució mitja`,
    DIFICULTAD_EJECUCION_ELEVADA: `Dificultat d’execució elevada`,
    CONDICIONES_DE_CONTRATACION: 'condicions de contractació',
    MANIFIESTO_LEIDO_Y_ACEPTO_LAS: 'Manifesto que he llegit i accepto les',
    PROTECCION_DATOS_CARACTER_PERSONAL: 'protecció de dades de caràcter personal',
    MANIFIESTO_LEIDO_Y_ACEPTO_INFORMACION_RELATIVA_A: 'Manifesto que he llegit i accepto la informació relativa a la',
    MANIFIESTO_LEIDO_ACEPTO_QUE: 'Manifesto que he llegit i accepto que',
    SI_FACILITO_DATOS_TERCERAS_PERSONAS: `si facilito les dades a terceres persones, hauré d'informar i obtenir el seu consentiment previ en relació amb els aspectes relacionats al present document`,
    CONDICIONES_REGISTRO: 'condicions de registre',
    SUBIR_SEPA_FINALIZAR: 'Pujar SEPA i finalitzar',
    IMPORTE_UNIDAD_ENTIDAD_JURIDICA: `Import per unitat d'entitat jurídica`,
    NUMERO_ENTIDADES_JURIDICAS: `Nombre d'entitats jurídiques`,
    EL_SIGUIENTE_ENLACE: `el següent enllaç`,
    ESPECIALISTA_ASIGNADO_CORRECTAMENTE: `L'especialista s'ha assignat correctament.`,
    NO_ENCONTRADO__NIF_ESPECIALISTA: `No s'ha trobat el NIF de l'especialista al nostre sistema.`,
    PAGO_DOMICILIACION: 'Pagament per domiciliació',
    TOTAL_FASES: 'Total Fases',
    TEXTO_PRELIMINAR: 'Text preliminar',
    TEXTO_PRELIMINAR_PLAN: 'Text preliminar del Pla',
    CREAR_CUENTA: 'Crear compte',
    NUEVA_PROPIEDAD: 'Nova propietat',
    CANTIDAD_TOTAL: 'Quantitat total',
    CANTIDAD_ENVIADOS: 'Quantitat enviats',
    HERRAMIENTA_DIGITAL_REDACCION: `EINA DIGITAL DE SUPORT A LA REDACCIÓ DEL LLIBRE DE L'EDIFICI`,
    HERRAMIENTA_DIGITAL_GESTION: `EINA DIGITAL DE GESTIÓ INTEGRAL D'EDIFICIS`,
    PROPIETARIO_A: 'Propietari/ària',
    REPRESENTANTE_LEGAL_PROPIEDAD: 'Representant legal propietat',
    AUTORIZADO_A_PROPIEDAD: 'Autoritzat/da propietat',
    PRESIDENTE_A_PROPIEDAD: 'President/a propietat',
    ADMINISTRADOR_A_FINCAS: 'Administrador/a de finques',
    ACCESO_USUARIOS: 'Accés usuaris',
    DATOS_PERSONALES: 'Dades personals',
    DATOS_CONTACTO: 'Dades de contacte',
    SELECCION_ENTIDADES_JURIDICAS_EDIFICIO: `Selecció d'entitats jurídiques que conformen l'edifici`,
    TRANSFERIR_EDIFICIO: 'Transferir edifici',
    NIF_NIE_NUEVO_PROPIETARIO: `NIF / NIE del nou propietari de l'edifici virtual`,
    TRANSFERENCIA_EXISTOSA: `S'ha transferit l'edifici`,
    ACEPTAR_TRANSFERENCIA_EDIFICIO: `Acceptar transferència de l'edifici`,
    IR_AL_EDIFICIO: `Anar a l'edifici`,
    DURACION_CONTRATO: 'Durada del contracte',
    DIVISIONES: 'Divisions',
    COMUNICACIONES_PARA_USUARIO: `Comunicacions per a l'usuari`,
    AVISO_VENCIMIENTO_DOCS_ASOCIADOS_EDIFICIOS: `Avís de venciment de documents associats a edificis`,
    AVISO_VENCIMIENTO_DOCS_ASOCIADOS_VIVIENDAS: `Avís de venciment de documents associats a habitatges`,
    CERTIFICADO_APTITUD: `Certificat d'aptitud`,
    NUMERO_EXPENDIENTE: `Número d'expedient`,
    VIGENCIA_LIMITE_CERTIFICADO: `Vigència límit del certificat`,
    CUALIFICACION_CERTIFICADO_APTITUD: `Qualificació del certificat d'aptitud`,
    FECHA_PRIMERA_VERIFICACION_OBLIGATORIA: `Data de la primera verificació tècnica obligatòria que cal realitzar`,
    FECHA_RECEPCION_INFORME_ITE: `Data de recepció de l'informe ITE per part de la propietat`,
    PLAZO_MAXIMO_PROGRAMA_REHABILITACION: `Termini màxim per aprovar el Programa de Rehabilitació`,
    APTO_CAUTELARMENTE: `Apte cautelarment`,
    APTO_PROVISIONALMENTE: `Apte provisionalment`,
    APTO: `Apte`,
    PROPIETARIO: `Propietari`,
    PROFESIONAL: `Professional`,
    CODIGO_INFORME_ITE: `Codi de l'informe ITE`,
    FECHA_EMISION_INFORME_ITE: `Data d'emissió de l'informe ITE`,
    REDACTOR_ITE: `Redactor ITE`,
    NUMERO_COLEGIADO: `Número de col·legiat`,
    AGREGAR_ESPECIALISTA: `Afegir especialista`,
    NECESARIO_AGREGAR_ESPECIALISTA: `Per poder dur a terme la gestió del manteniment cal afegir un tècnic especialista en gestió del manteniment a la pantalla`,
    CONTACTA_TECNICOS_ESPECIALISTAS_LOCALIZACION: `Contacta amb tècnics especialistes en gestió del manteniment segons la localització de l'edifici`,
    CUENTA_ENCONTRADA: `Compte trobat`,
    CERRAR: 'Tancar',
    CERRAR_SIN_SEPA: `Tancar sense SEPA`,
    ACTIVIDADES_PROXIMAS: `Activitats properes`,
    DESCARGAR_EXCEL: `Descarregar Excel`,
    DESCARGANDO: `Descarregant`,
    HISTORICO_PROPIEDADES: `Històric de propietats`,
    CATALUNIA: 'Catalunya',
    GALICIA: 'Galicia',
    ISLAS_BALEARES: 'Illes Balears',
    PAIS_VASCO: 'País Basc',
    NAVARRA: "Navarra",
    ABRIR: "Obrir",
    VER_MANT_PREVENTIVO: `Veure Mant. Preventiu`,
    VER_MANT_CORRECTIVO: `Veure Mant. Correctiu`,
    VER_MEJORA: `Veure Millora`,
    FINALIZAR_ACTIVIDAD: `Finalitzar activitat`,
    FECHA_FIN_DESDE: `Data fi des de`,
    FECHA_FIN_HASTA: `Data fi fins`,
    ACTUALIZAR_DATOS_USUARIO: `Actualitzar dades d'usuari`,
    NUMERO_COLEGIADO_A: `Número de col·legiat/da`,
    ESCRIBA_SU_NIF_NIE: `Escriviu el vostre NIF / NIE`,
    INCLUIR_EN_LIBRO_EDIFICIO: `Incloure al Llibre de l'edifici`,
    CASTILLA_Y_LEON: `Castilla y León`,
    AGREGAR_PROPIEDAD: `Afegeix propietat`,
    EDITAR_PROPIEDAD: `Editar propietat`,
    PERSONA_JURIDICA: `Persona jurídica`,
    TERRITORIO_NACIONAL: 'Resta del territori',
    EDIFICIO_SITUADO_EN: "L'edifici està situat a",
    PASAR_A_HISTORICO: `Passar a històric`,
    NOTA_INFO_REGISTRO: `L'alta d'un edifici es fa sincronitzant automàticament amb les dades de la Seu electrònica de cadastre de les diferents províncies i ciutats autònomes del territori espanyol, excepte País Basc i Navarra que disposen dels seus corresponents cadastres autonòmics o provincials`,
    INCLUIR_EN_EL_MANUAL_DE_LA_VIVIENDA: `Incloure al Manual de l'habitatge`,
    CANARIAS: `Canarias`,
    VER_DESTINATARIOS: `Veure destinataris`,
    ENVIADO_POR: `Enviat per`,
    SOLO_SE_ADMINTEN: `Només s'admeten documents en format`,
    TOTAL_DESTINATARIOS: `Total destinataris`,
    VER_MENSAJE: `Veure missatge`,
    NUEVO_INGRESO: `Nou ingrés`,
    EDITAR_INGRESO: `Editar ingrés`,
    TIPO_INGRESO: `Tipus d'ingrés`,
    INGRESO: `Ingrés`,
    FINALIZAR_DERRAMA: `Finalitzar vessa`,
    PREGUNTAS_FRECUENTES: `Preguntes freqüents`,
    COLABORACION_DE: `Amb la col·laboració de`,
    TECNICO_REDACTOR: `Tècnic redactor`,
    GESTOR_MANTENIMIENTO: `Gestor de manteniment`,
    PROMOTOR: `Promotor`,
    ANDALUCIA: `Andalucía`,
    ALERTA_NOTIFICACIONES_1: `Les notificacions arriben amb`,
    ALERTA_NOTIFICACIONES_2: `dies d'antelació`,
    TRANSFERENCIA_EXITOSA: `S'ha transferit l'edifici al propietari nou`,
    MAX: `Màx`,
    SE_HA_EXCEDIDO_PESO_PERMITIDO: `S'ha excedit el pes permès`,
    NOTA: `Nota`,
    ALERTA_NOTIFICACIONES_EDIFICIO: `Les notificacions de preavís de caducitat dels documents es fa amb una antelació de 3 mesos (90 dies) sempre que s’hagi identificat aquesta data al carregar l’arxiu a la Biblioteca de documents.`,
    ALERTA_NOTIFICACIONES_VIVIENDAS: `Les notificacions de preavís de caducitat dels documents es fa amb una antelació de 3 mesos (90 dies) sempre que s’hagi identificat aquesta data al carregar l’arxiu a la Biblioteca de documents.`,
    ALERTA_NOTIFICACIONES_ACTIVIDADES: `Les notificacions de preavís d’inici d’una activitat programada es fa amb una antelació de 3 mesos (90 dies).`,
    ALERTA_DOCUMENTO_ADJUNTOS_GENERADOR_LIBRO: `En cas d'adjuntar documents de la biblioteca que han esgotat la data de validesa, aquests no s’inclouran al Llibre de l’edifici. En cas de voler-los incloure caldrà actualitzar la seva data de validesa o eliminar la data en cas de no haver-los de renovar (en aquest cas, també deixaran d’aparèixer les notificacions de caducitat del document).`,
    FICHA: `Fitxa`,
    VER_VIDEO_PRESENTACION: `Veure vídeo de presentació`,

}

export default GENERALS