import { useState } from 'react'
import ModalAdvanced from '../../components/ModalAdvanced/ModalAdvanced'
import { Grid, Typography } from '@mui/material'
import { useLang } from '../../language'
import { useNavigate } from 'react-router'
import { ButtonAdvanced } from '../../components/ButtonAdvanced/ButtonAdvanced'
import { TagTopProducto } from '../../components/TagTopProducto/TagTopProducto'
import { useModalData } from '../../hooks/useModalData'
import ReactPlayer from 'react-player'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'

export const ModalTarifa1 = ({ data, handleClose, handleShowModal_Registro = () => null }: any) => {

    const Lang = useLang()

    const navigate = useNavigate()

    const [dataModal_video, handleShowModal_video, handleCloseModal_video] = useModalData()

    const { language } = useSelector((state: RootState) => state.language)

    return (
        <>
            {/* <ModalAdvanced data={data} handleClose={handleClose} size='md'> */}

            {/* <ModalAdvanced.Head> */}
            {/* <TagTopProducto title={Lang('PROFESIONALES')} /> */}

            <Typography variant="h5" component="h2" className='fw-bold text-center mb-3 color-primary'>
                {Lang('LED_REDACCION')}
            </Typography>
            {/* </ModalAdvanced.Head> */}

            <ModalAdvanced.Body>

                <Typography variant="subtitle1" component="h5" className='text-center m-0'>
                {Lang('HERRAMIENTA_DIGITAL_REDACCION')}.
                </Typography>

                <Grid container justifyContent='center' spacing={0} className='py-4'>

                    <Grid item md={6} className=''>

                        <div className="border-end text-center">

                            <Typography variant="h6" component="h5" className='text-center fw-normal my-2'>
                                {Lang('TARIFA_GENERAL')}
                            </Typography>

                            <Typography className='text-center fw-bold' variant="h5">
                                50€ / {Lang('LIBRO')}
                            </Typography>
                            <small>
                                ({Lang('SIN_IVA')})
                            </small>
                        </div>

                    </Grid>

                    <Grid item md={6} className=' text-center'>

                        <Typography variant="h6" component="h5" className='text-center fw-normal my-2'>
                            {Lang('TARIFA_CONVENIO')}
                        </Typography>

                        <Typography className='text-center fw-bold' variant="h5">
                            30€ / {Lang('LIBRO')}
                        </Typography>
                        <small>
                            ({Lang('SIN_IVA')})
                        </small>
                    </Grid>

                </Grid>

                <div>
                    <small>
                        <strong>{Lang('CONDICIONES')}:</strong>
                    </small>
                </div>
                <small>
                    <ul className='ps-4'>
                        {/* <li>{Lang('PARRAFO_PRECIOS_INCLUYEN_IVA')}.</li> */}
                        <li>{Lang('PARRAFO_USUARIOS_CONVENIO_COMPRA')}.</li>
                    </ul>
                </small>

                <p className='my-1'>
                    <small>{Lang('PARRAFO_CONSULTA_CONVENIOS')} <a href="/" onClick={e => {
                        // handleClose()
                        let hero = document.getElementById("seccion-convenios");
                        e.preventDefault();
                        hero && hero.scrollIntoView({ behavior: "smooth", block: "start" });
                    }} className='link-primary color-primary text-decoration-underline'>{Lang('EN_ESTE_ENLACE')}</a> </small>
                </p>

                <span className="text-uppercase cursor-pointer color-primary fw-500 text-decoration-underline mt-3" onClick={handleShowModal_video}>
                    {Lang('VER_VIDEO_PRESENTACION')}
                </span>

            </ModalAdvanced.Body>

            <ModalAdvanced.Footer className='mt-auto ms-auto flex-basis-0'>
                <div className="d-flex justify-content-end">
                    {/* <ButtonAdvanced
                            variant="outlined" type="button" className="" color='secondary'
                            handleSubmit={handleClose}
                            // handleSubmit={handleSubmitRestablecerContrasenia}
                            // loadings={[fetchAuthPOSTData.loading]}
                            value={Lang('SALIR')}
                        /> */}
                    <ButtonAdvanced
                        variant="contained" type="button" className=""
                        handleSubmit={handleShowModal_Registro}
                        // handleSubmit={handleSubmitRestablecerContrasenia}
                        // loadings={[fetchAuthPOSTData.loading]}
                        value={Lang('REGISTRARSE_Y_COMPRAR')}
                    />
                </div>
            </ModalAdvanced.Footer>

            {/* </ModalAdvanced> */}

            
            <ModalAdvanced data={dataModal_video} handleClose={handleCloseModal_video} size='xl'>

                <ModalAdvanced.Head>

                </ModalAdvanced.Head>

                <ModalAdvanced.Body className='mb-0'>

                    <div className="" >
                        <ReactPlayer
                            className="react-player"
                            width="100%"
                            height="60vh"
                            url={language === 'ES' ? 'https://vimeo.com/946883658' : 'https://vimeo.com/950732290' }
                            controls={true}
                        />
                    </div>

                </ModalAdvanced.Body>


            </ModalAdvanced>

        </>
    )
}